import * as React from "react"

// markup
const NotFoundPage = () => {
  return (
    <span>NOT FOUND</span>
  )
}

export default NotFoundPage
